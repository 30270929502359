import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Box } from "@chakra-ui/react";
export function HeaderAll() {
  const { allCategories } = useContext(AppContext);

  return (
    <>
      {/* <div id="preloader-active">
        <div class="preloader d-flex align-items-center justify-content-center">
          <div class="preloader-inner position-relative">
            <div class="preloader-circle"></div>
            <div class="preloader-img pere-text">
              <img src="img/icon/loder.png" alt="loder" />
            </div>
          </div>
        </div>
      </div> */}
      <header>
        <div class="header-area">
          <div class="header-top d-none d-sm-block">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="d-flex justify-content-between flex-wrap align-items-center">
                    <div class="header-info-left">
                      <ul>
                        {/* <li>
                          <a href="/about">About Us</a>
                        </li>
                        <li><a href="index.html#">Privacy</a></li>
                        <li>
                          <a href="/faq">FAQ</a>
                        </li>
                        <li><a href="index.html#">Careers</a></li> */}
                      </ul>
                    </div>
                    <div class="header-info-right d-flex">
                      <ul class="order-list">
                        {/* <li><a href="index.html#">My Wishlist</a></li> */}
                        {/* <li><a href="index.html#">Track Your Order</a></li> */}
                      </ul>
                      <ul class="header-social">
                        <li>
                          <a href="https://www.facebook.com/evytyfabrics/">
                            <i class="fab fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                                <a href="https://www.instagram.com/wendystrimandfabrics/?igsh=eHIzaWhwZXprMXM3&utm_source=qr#"><i class="fab fa-instagram"></i></a>
                            </li>
                            {/* <li>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                            </li> */}
                        <li>
                          <a href="https://www.linkedin.com/company/evyty-pty-ltd">
                            <i class="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        {/* <li>
                                <a href="index.html#"><i class="fab fa-youtube"></i></a>
                            </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="header-mid header-sticky">
            <div class="container">
              <div class="menu-wrapper">
                <div class="logo">
                  <a href="/">
                    <img src="/img/logo/logo33.png" alt="logo" />
                  </a>
                </div>

                <div class="main-menu d-none d-lg-block">
                  <nav>
                    <ul id="navigation">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/category">
                          Categories <i class="fas fa-angle-down"></i>
                        </a>
                        {/* add cats*/}
                        <ul class="submenu">
                          {allCategories.map((c) => (
                            <li>
                              <a href={`/category/${c.ID}`}>{c.NAME}</a>
                            </li>
                          ))}
                        </ul>
                      </li>
                      {/* <li>
                        <a href="/colors">Colours</a>
                      </li> */}
                      <li>
                        <a href="/free_cutting">Free Cutting</a>
                      </li>
                      <li>
                        <a href="/trade">Trade Customers</a>
                      </li>
                      <li>
                        <a href="/about">About Us</a>
                      </li>
                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div class="header-right" style={{ display: "none" }}>
                  <ul>
                    <li>
                      <div class="nav-search search-switch hearer_icon">
                        <a id="search_1" href="#id">
                          <span class="flaticon-search"></span>
                        </a>
                      </div>
                    </li>
                    <li>
                      <a href="/login">
                        <span class="flaticon-user"></span>
                      </a>
                    </li>
                    <li class="cart">
                      <a href="/cart">
                        <span class="flaticon-shopping-cart"></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <Box width="80px">&nbsp;</Box>
              </div>

              <div
                class="search_input"
                id="search_input_box"
                style={{ display: "none" }}
              >
                <form class="search-inner d-flex justify-content-between">
                  <input
                    type="text"
                    class="form-control"
                    id="search_input"
                    placeholder="Search Here"
                  />
                  <button type="submit" class="btn"></button>
                  <span
                    class="ti-close"
                    id="close_search"
                    title="Close Search"
                  ></span>
                </form>
              </div>

              <div class="col-12">
                <div class="mobile_menu d-block d-lg-none"></div>
              </div>
            </div>
          </div>
          <div class="header-bottom text-center" style={{ display: "none" }}>
            <p>
              Sale Up To 50% Biggest Discounts. Hurry! Limited Perriod Offer
              <a href="index.html#" class="browse-btn">
                Shop Now
              </a>
            </p>
          </div>
        </div>
      </header>
    </>
  );
}
